@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.bg-white-half {
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, transparent 50%);
}

@media (max-width: 768px) {
  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .mobile-scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .mobile-scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.tag-space > *:not(:last-child) {
  margin-right: 8px;
}
